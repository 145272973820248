<template>
  <div>
    <!-- Mobile layout -->
    <responsive-layout
      v-if="$vuetify.breakpoint.mobile"
      poster="https://vod.eventcdn.net/pandora/smc2021/Theatre_Bg.png"
    >
      <v-sheet
        style="min-height: 100vh"
        class="d-flex flex-column justify-center transparent"
        dark
      >
        <div class="main py-8 flex-fill">
          <v-container fluid>
            <!-- 'Breadcrumb' navigation at the top -->
            <v-row justify="start" align="start" class="mt-5 lobby-router-row">
              <v-col
                cols="12"
                class="pl-0 d-flex align-start justify-start flex-column ml-1"
              >
                <!--<v-btn
                  class="ma-2 white--text lobby-back-button rounded-0 mr-0 mb-0"
                  color="black"
                >
                  <router-link class="white--text" to="/lobby">
                    <v-icon left dark color="pink" class="back-arrow-theatre">
                      fas fa-chevron-left
                    </v-icon>
                    <span class="back-arrow-lobby-link">To lobby</span>
                  </router-link>
                </v-btn>-->
                <AppBackButton :to="{ name: 'Lobby' }" class="ml-2"
                  >TO LOBBY</AppBackButton
                >
                <div class="route-name-background-container-information ml-2">
                  <span class="theatre-route-name">{{ $route.meta.name }}</span>
                </div>
              </v-col>
            </v-row>

            <!-- Introduction video section -->
            <v-row
              justify="center"
              align="center"
              class="mt-5 sessions-agenda-lobby-row-with-background"
            >
              <v-col cols="12">
                <div
                  class="pl-2 py-3 black text-left white--text"
                  style="font-size: 1.2rem"
                >
                  <h3 class="pa-4">INTRODUCTION VIDEO</h3>
                </div>
                <div class="pl-2 pr-4 py-6 d-flex white flex-row flex-wrap">
                  <v-col cols="12" class="d-flex flex-row flex-wrap">
                    <v-col cols="12" class="agenda-container d-flex">
                      <v-dialog
                        :max-width="
                          $vuetify.breakpoint.mobile ? '75vw' : '50vw'
                        "
                        @click:outside="$refs.introductionVideoRef.pause()"
                        @keydown="$refs.introductionVideoRef.pause()"
                      >
                        <template #activator="{ on }">
                          <v-responsive :aspect-ratio="16 / 9">
                            <div
                              style="
                                height: 100%;
                                width: 100%;
                                background-size: cover;
                                background-repeat: no-repeat;
                              "
                              class="d-flex align-center justify-center"
                              :style="{
                                'background-image': `url(${introductionVideo.splash})`,
                              }"
                            >
                              <v-btn
                                fab
                                color="primary"
                                class="rounded-circle"
                                v-on="on"
                                ><v-icon> fas fa-play</v-icon></v-btn
                              >
                            </div>
                          </v-responsive>
                        </template>

                        <v-responsive :aspect-ratio="16 / 9">
                          <video
                            ref="introductionVideoRef"
                            autoplay
                            controls
                            preload="metadata"
                            style="height: 100%; width: 100%"
                            :poster="introductionVideo.splash"
                            @ended="onIntroductionVideoEnd"
                          >
                            <source :src="introductionVideo.src" /></video
                        ></v-responsive>
                      </v-dialog>

                      <v-col cols="12" sm="6" class="text-left">
                        <p class="session-date">{{ introductionVideo.time }}</p>
                        <h2 class="session-title mb-4">
                          {{ introductionVideo.title }}
                        </h2>
                        <p
                          class="session-name"
                          v-for="(speaker, idx) in introductionVideo.speakers"
                          :key="idx"
                        >
                          {{ speaker.name }}
                        </p>
                        <p
                          class="session-points mt-12"
                          v-if="event.state !== 'ONDEMAND'"
                        >
                          {{ introductionVideo.points }} pts
                        </p>
                      </v-col>
                    </v-col>
                  </v-col>
                </div>
              </v-col>
            </v-row>

            <!-- Sessions in agenda section -->
            <v-row
              justify="center"
              align="center"
              class="mt-5 sessions-agenda-lobby-row"
            >
              <v-col cols="12" class="pb-0">
                <div
                  class="pl-2 py-3 black text-left white--text"
                  style="font-size: 1.2rem"
                >
                  <h3 class="pa-4">SESSIONS</h3>
                </div>
                <div class="pl-2 pr-4 py-6 d-flex white flex-row flex-wrap">
                  <v-col cols="8" sm="4" class="mt-4 mb-4 d-flex">
                    <v-col cols="6" class="pink pink-date-pill d-flex pa-0">
                      <p class="mb-0">TUESDAY</p>
                    </v-col>
                    <v-col cols="6" class="lighter-grey grey-date-pill pa-0">
                      <span class="mb-0 date-span"> 14 </span>
                      <span class="ml-1">SEP</span>
                    </v-col>
                  </v-col>
                  <v-col cols="12" class="d-flex flex-row flex-wrap">
                    <v-col
                      cols="12"
                      v-for="session in sessions.filter(
                        (session) => session.day === 1
                      )"
                      :key="session.id"
                      class="agenda-container mt-4 mb-4 d-flex"
                    >
                      <v-col cols="12" sm="6" v-if="event.state === 'ONDEMAND'">
                        <v-lazy>
                          <v-responsive :aspect-ratio="16 / 9">
                            <div
                              style="
                                background-size: cover;
                                background-repeat: no-repeat;
                                height: 100%;
                                width: 100%;
                              "
                              :style="{
                                'background-image': `url(${session.details.thumb})`,
                              }"
                              class="d-flex align-center justify-center"
                            >
                              <v-btn
                                fab
                                color="primary"
                                class="rounded-circle"
                                :to="{
                                  name: 'SessionDetails',
                                  params: { id: session.id },
                                }"
                                ><v-icon> fas fa-play</v-icon></v-btn
                              >
                            </div>
                          </v-responsive>
                        </v-lazy>
                      </v-col>
                      <v-col cols="12" sm="6" class="text-left">
                        <v-chip
                          small
                          label
                          color="background"
                          class="white--text mb-4"
                          v-if="event.state === 'ONDEMAND'"
                          >ON DEMAND</v-chip
                        >
                        <p class="session-date" v-else>
                          {{ session.details.time }}
                        </p>
                        <h2 class="session-title mb-4">{{ session.name }}</h2>
                        <div class="d-flex flex-wrap">
                          <p
                            class="session-name"
                            v-for="(speaker, idx) in session.speakers"
                            :key="idx"
                          >
                            <template v-if="idx !== 0">, </template
                            >{{ speaker.name }}
                          </p>
                        </div>

                        <div
                          class="
                            d-flex
                            align-center
                            justify-space-between
                            mt-12
                          "
                        >
                          <p
                            class="session-points mb-0"
                            v-if="event.state !== 'ONDEMAND'"
                          >
                            {{ session.details.points }} pts
                          </p>

                          <v-btn
                            v-if="session.more_details"
                            style="text-transform: unset"
                            class="
                              text-decoration-underline
                              session-points
                              px-0
                            "
                            text
                            :to="{
                              name: 'SessionDetails',
                              params: { id: session.id },
                            }"
                            >See more details</v-btn
                          >
                        </div>
                      </v-col>
                    </v-col>
                  </v-col>
                </div>
              </v-col>
            </v-row>

            <v-row
              justify="center"
              align="center"
              class="sessions-agenda-lobby-row mt-0"
            >
              <v-col cols="12" class="pt-0">
                <v-divider class="pink"></v-divider>

                <div class="pl-2 pr-4 py-6 d-flex white flex-row flex-wrap">
                  <v-col cols="8" sm="4" class="mt-4 mb-4 d-flex">
                    <v-col cols="6" class="pink pink-date-pill d-flex pa-0">
                      <p class="mb-0">WEDNESDAY</p>
                    </v-col>
                    <v-col cols="6" class="lighter-grey grey-date-pill pa-0">
                      <span class="mb-0 date-span"> 15 </span>
                      <span class="ml-1">SEP</span>
                    </v-col>
                  </v-col>
                  <v-col cols="12" class="d-flex flex-row flex-wrap">
                    <v-col
                      cols="12"
                      v-for="session in sessions.filter(
                        (session) => session.day === 2
                      )"
                      :key="session.id"
                      class="agenda-container mt-4 mb-4 d-flex"
                    >
                      <v-col cols="12" sm="6" v-if="event.state === 'ONDEMAND'">
                        <v-lazy>
                          <v-responsive :aspect-ratio="16 / 9">
                            <div
                              style="
                                background-size: cover;
                                background-repeat: no-repeat;
                                height: 100%;
                                width: 100%;
                              "
                              :style="{
                                'background-image': `url(${session.details.thumb})`,
                              }"
                              class="d-flex align-center justify-center"
                            >
                              <v-btn
                                fab
                                color="primary"
                                class="rounded-circle"
                                :to="{
                                  name: 'SessionDetails',
                                  params: { id: session.id },
                                }"
                                ><v-icon> fas fa-play</v-icon></v-btn
                              >
                            </div>
                          </v-responsive>
                        </v-lazy>
                      </v-col>
                      <v-col cols="12" sm="6" class="text-left">
                        <v-chip
                          small
                          label
                          color="background"
                          class="white--text mb-4"
                          v-if="event.state === 'ONDEMAND'"
                          >ON DEMAND</v-chip
                        >
                        <p class="session-date" v-else>
                          {{ session.details.time }}
                        </p>
                        <h2 class="session-title mb-4">{{ session.name }}</h2>
                        <div class="d-flex flex-wrap">
                          <p
                            class="session-name"
                            v-for="(speaker, idx) in session.speakers"
                            :key="idx"
                          >
                            <template v-if="idx !== 0">, </template
                            >{{ speaker.name }}
                          </p>
                        </div>

                        <div
                          class="
                            d-flex
                            align-center
                            justify-space-between
                            mt-12
                          "
                        >
                          <p
                            class="session-points mb-0"
                            v-if="event.state !== 'ONDEMAND'"
                          >
                            {{ session.details.points }} pts
                          </p>

                          <v-btn
                            v-if="session.more_details"
                            style="text-transform: unset"
                            class="
                              text-decoration-underline
                              session-points
                              px-0
                            "
                            text
                            :to="{
                              name: 'SessionDetails',
                              params: { id: session.id },
                            }"
                            >See more details</v-btn
                          >
                        </div>
                      </v-col>
                    </v-col>
                  </v-col>
                </div>
              </v-col>
            </v-row>

            <v-row
              justify="center"
              align="center"
              class="sessions-agenda-lobby-row mt-0"
            >
              <v-col cols="12" class="pt-0">
                <v-divider class="pink"></v-divider>

                <div class="pl-2 pr-4 py-6 d-flex white flex-row flex-wrap">
                  <v-col cols="8" sm="4" class="mt-4 mb-4 d-flex">
                    <v-col cols="6" class="pink pink-date-pill d-flex pa-0">
                      <p class="mb-0">THURSDAY</p>
                    </v-col>
                    <v-col cols="6" class="lighter-grey grey-date-pill pa-0">
                      <span class="mb-0 date-span"> 16 </span>
                      <span class="ml-1">SEP</span>
                    </v-col>
                  </v-col>
                  <v-col cols="12" class="d-flex flex-row flex-wrap">
                    <v-col
                      cols="12"
                      v-for="session in sessions.filter(
                        (session) => session.day === 3
                      )"
                      :key="session.id"
                      class="agenda-container mt-4 mb-4 d-flex"
                    >
                      <v-col cols="12" sm="6" v-if="event.state === 'ONDEMAND'">
                        <v-lazy>
                          <v-responsive :aspect-ratio="16 / 9">
                            <div
                              style="
                                background-size: cover;
                                background-repeat: no-repeat;
                                height: 100%;
                                width: 100%;
                              "
                              :style="{
                                'background-image': `url(${session.details.thumb})`,
                              }"
                              class="d-flex align-center justify-center"
                            >
                              <v-btn
                                fab
                                color="primary"
                                class="rounded-circle"
                                :to="{
                                  name: 'SessionDetails',
                                  params: { id: session.id },
                                }"
                                ><v-icon> fas fa-play</v-icon></v-btn
                              >
                            </div>
                          </v-responsive>
                        </v-lazy>
                      </v-col>
                      <v-col cols="12" sm="6" class="text-left">
                        <v-chip
                          small
                          label
                          color="background"
                          class="white--text mb-4"
                          v-if="event.state === 'ONDEMAND'"
                          >ON DEMAND</v-chip
                        >
                        <p class="session-date" v-else>
                          {{ session.details.time }}
                        </p>
                        <h2 class="session-title mb-4">{{ session.name }}</h2>
                        <div class="d-flex flex-wrap">
                          <p
                            class="session-name"
                            v-for="(speaker, idx) in session.speakers"
                            :key="idx"
                          >
                            <template v-if="idx !== 0">, </template
                            >{{ speaker.name }}
                          </p>
                        </div>

                        <div
                          class="
                            d-flex
                            align-center
                            justify-space-between
                            mt-12
                          "
                        >
                          <p
                            class="session-points mb-0"
                            v-if="event.state !== 'ONDEMAND'"
                          >
                            {{ session.details.points }} pts
                          </p>

                          <v-btn
                            v-if="session.more_details"
                            style="text-transform: unset"
                            class="
                              text-decoration-underline
                              session-points
                              px-0
                            "
                            text
                            :to="{
                              name: 'SessionDetails',
                              params: { id: session.id },
                            }"
                            >See more details</v-btn
                          >
                        </div>
                      </v-col>
                    </v-col>
                  </v-col>
                </div>
              </v-col>
            </v-row>

            <!-- Dare to explore section -->
            <v-row class="mt-5 sessions-agenda-lobby-row">
              <v-col cols="12">
                <div
                  class="pl-2 py-3 black text-left white--text"
                  style="font-size: 1.2rem"
                >
                  <h3 class="pa-4">EXTRA SESSIONS</h3>
                </div>
                <div class="pl-2 pr-4 py-6 d-flex white flex-row flex-wrap">
                  <v-col cols="12" class="d-flex flex-row flex-wrap">
                    <v-col
                      cols="12"
                      v-for="(session, idx) in extraSessions"
                      :key="idx"
                      class="agenda-container mt-4 mb-4 d-flex"
                    >
                      <v-col cols="12" sm="6">
                        <!--<v-img
                          :src="session.image"
                          style="max-width: 100%"
                        ></v-img>-->
                      </v-col>
                      <v-col cols="12" sm="6" class="text-left">
                        <p class="session-date">
                          {{ session.details.time }}
                        </p>
                        <h2 class="session-title mb-4">{{ session.name }}</h2>
                        <div class="d-flex flex-wrap">
                          <p
                            class="session-name"
                            v-for="(speaker, speakerIdx) in session.speakers"
                            :key="speakerIdx"
                          >
                            <template v-if="idx !== 0">, </template
                            >{{ speaker.name }}
                          </p>
                        </div>

                        <p
                          class="session-points mt-12"
                          v-if="event.state !== 'ONDEMAND'"
                        >
                          {{ session.details.points }} pts
                        </p>
                      </v-col>
                    </v-col>
                  </v-col>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-sheet>
    </responsive-layout>

    <!-- 16:9 layout for desktop devices -->
    <aspect-ratio-layout
      v-else
      poster="https://vod.eventcdn.net/pandora/smc2021/backgrounds/Theatre_loop.jpg"
      background-video-initial="https://vod.eventcdn.net/pandora/smc2021/BackgroundVideo/Theatre.mp4"
      background-video-loop="https://vod.eventcdn.net/pandora/smc2021/BackgroundVideo/loop/Theatre_loop.mp4"
      #default="{ replayBackgroundVideo, isLooping }"
    >
      <v-sheet class="d-flex flex-column transparent" light>
        <div class="main">
          <template v-if="isLooping">
            <div style="position: fixed; right: 353px; top: 69%">
              <div style="position: relative" class="d-flex align-center">
                <v-btn
                  color="black"
                  class="play-btn pr-7"
                  style="position: unset"
                >
                  <span class="white--text play-btn-text">WELCOME</span>
                </v-btn>

                <v-btn
                  fab
                  class="rounded-circle ml-n4"
                  color="white"
                  @click="replayBackgroundVideo"
                  style="z-index: 16"
                >
                  <v-icon dark color="pink"> fas fa-play </v-icon>
                </v-btn>
              </div>
            </div>
          </template>
          <v-container fluid>
            <!-- 'Breadcrumb' navigation at the top -->
            <v-row justify="start" align="start" class="mt-5 lobby-router-row">
              <v-col
                cols="8"
                class="pl-0 d-flex align-start justify-start ml-10"
              >
                <AppBackButton class="mt-2" :to="{ name: 'Lobby' }"
                  >TO LOBBY</AppBackButton
                >
                <div
                  class="
                    route-name-background-container-meet-your-colleagues
                    mt-2
                  "
                >
                  <span class="theatre-route-name">{{ $route.meta.name }}</span>
                </div>
              </v-col>
            </v-row>

            <!-- Session is live section -->
            <v-row
              v-if="event.state === 'LIVE'"
              justify="start"
              align="start"
              class="mt-5 sessions-agenda-lobby-row"
            >
              <v-col
                cols="8"
                class="session-countdown-container-theatre"
                style="height: 200px"
              >
                <div
                  class="d-flex justify-space-between align-center px-4"
                  style="height: 100%"
                >
                  <div
                    class="d-flex align-center day-one-text white--text"
                    style="gap: 1rem"
                  >
                    <v-chip label color="red" class="white--text">LIVE</v-chip>
                    {{ currentSession.name }} is live!
                  </div>

                  <v-btn
                    color="primary"
                    :to="{ name: 'Session' }"
                    class="black--text font-weight-bold"
                    >JOIN NOW</v-btn
                  >
                </div>
              </v-col>
            </v-row>

            <!-- Secondary event -->
            <v-row
              v-if="
                event.state === 'LIVE' &&
                currentSession &&
                currentSession.details &&
                currentSession.details.extra_session
              "
              justify="start"
              align="start"
              class="mt-5 sessions-agenda-lobby-row"
            >
              <v-col
                cols="8"
                class="session-countdown-container-theatre"
                style="height: 200px"
              >
                <div
                  class="d-flex justify-space-between align-center px-4"
                  style="height: 100%"
                >
                  <div
                    class="d-flex align-center day-one-text white--text"
                    style="gap: 1rem"
                  >
                    <v-chip label color="red" class="white--text">LIVE</v-chip>
                    Engraving is live!
                  </div>

                  <template>
                    <v-dialog
                      content-class="transparent elevation-0"
                      v-model="secondarySessionDialog"
                      persistent
                      :max-width="$vuetify.breakpoint.mobile ? '75vw' : '50vw'"
                    >
                      <template #activator="{ on }">
                        <v-btn
                          v-on="on"
                          color="primary"
                          class="black--text font-weight-bold"
                          >JOIN NOW</v-btn
                        >
                      </template>

                      <div
                        class="d-flex justify-end pa-4 black"
                        style="width: 100%"
                      >
                        <v-btn
                          icon
                          dark
                          color="pink"
                          @click="secondarySessionDialog = false"
                        >
                          <v-icon> fas fa-times-circle </v-icon>
                        </v-btn>
                      </div>

                      <v-responsive :aspect-ratio="16 / 9">
                        <iframe
                          allowfullscreen
                          frameborder="0"
                          src="https://streams.eventcdn.net/pandora/engraving"
                          style="height: 100%; width: 100%"
                        ></iframe
                      ></v-responsive>
                    </v-dialog>
                  </template>
                </div>
              </v-col>
            </v-row>

            <!-- OO Section -->

            <!-- OOSession is live section -->
            <v-row
              v-if="ooEvent.state === 'LIVE' && currentOOSession"
              justify="start"
              align="start"
              class="mt-5 sessions-agenda-lobby-row"
            >
              <v-col
                cols="8"
                class="session-countdown-container-theatre"
                style="height: 200px"
              >
                <div
                  class="d-flex justify-space-between align-center px-4"
                  style="height: 100%"
                >
                  <div
                    class="d-flex align-center day-one-text white--text"
                    style="gap: 1rem"
                  >
                    <v-chip label color="red" class="white--text">LIVE</v-chip>
                    {{ currentOOSession.name }} is live!
                  </div>

                  <template v-if="currentOOSession.oo_link">
                    <v-dialog
                      content-class="transparent elevation-0"
                      v-model="ooSessionDialog"
                      persistent
                      :max-width="$vuetify.breakpoint.mobile ? '75vw' : '50vw'"
                    >
                      <template #activator="{ on }">
                        <v-btn
                          v-on="on"
                          color="primary"
                          class="black--text font-weight-bold"
                          >JOIN NOW</v-btn
                        >
                      </template>

                      <div
                        class="d-flex justify-end pa-4 black"
                        style="width: 100%"
                      >
                        <v-btn
                          icon
                          dark
                          color="pink"
                          @click="ooSessionDialog = false"
                        >
                          <v-icon> fas fa-times-circle </v-icon>
                        </v-btn>
                      </div>

                      <v-responsive :aspect-ratio="16 / 9">
                        <iframe
                          allowfullscreen
                          frameborder="0"
                          :src="currentOOSession.oo_link"
                          style="height: 100%; width: 100%"
                        ></iframe
                      ></v-responsive>
                    </v-dialog>
                  </template>
                </div>
              </v-col>
            </v-row>
            <!-- End OO Section -->

            <!-- Introduction video section -->
            <v-row
              justify="start"
              align="start"
              class="mt-5 sessions-agenda-lobby-row"
              v-if="event.state === 'WAITING' || event.state === 'LIVE'"
            >
              <v-col
                cols="8"
                class="d-flex session-countdown-container-theatre"
                v-if="event.state === 'WAITING'"
                style="height: 200px"
              >
                <v-col cols="9" class="d-flex align-center justify-start">
                  <p class="white--text text-left day-one-text mb-0">
                    Day {{ event.day }} starts in:
                  </p>

                  <lh-countdown
                    class="ml-6 mb-n8"
                    :time="countdownTime"
                    value-class="countdown-value countdown-value-session-page"
                    content-class="white--text mt-n10"
                    :labels="{
                      days: 'Days',
                      hours: 'Hours',
                      minutes: 'Minutes',
                      seconds: 'Seconds',
                    }"
                  ></lh-countdown>
                </v-col>
                <v-col cols="3" class="d-flex align-center justify-end">
                  <v-btn
                    color="primary"
                    :to="{ name: 'Session' }"
                    class="black--text font-weight-bold"
                    >JOIN NOW</v-btn
                  >
                </v-col>
              </v-col>
            </v-row>

            <!-- OO Section is waiting -->
            <v-row
              justify="start"
              align="start"
              class="mt-5 sessions-agenda-lobby-row"
              v-if="
                (ooEvent.state === 'WAITING' || ooEvent.state === 'LIVE') &&
                currentOOSession
              "
            >
              <v-col
                cols="8"
                class="d-flex session-countdown-container-theatre"
                v-if="ooEvent.state === 'WAITING' && countdownOOTime"
                style="height: 200px"
              >
                <v-col cols="9" class="d-flex align-center justify-start">
                  <p class="white--text text-left day-one-text mb-0">
                    O&O Day {{ ooEvent.day }} starts in:
                  </p>

                  <lh-countdown
                    class="ml-6 mb-n8"
                    :time="countdownOOTime"
                    value-class="countdown-value countdown-value-session-page"
                    content-class="white--text mt-n10"
                    :labels="{
                      days: 'Days',
                      hours: 'Hours',
                      minutes: 'Minutes',
                      seconds: 'Seconds',
                    }"
                  ></lh-countdown>
                </v-col>
                <v-col cols="3" class="d-flex align-center justify-end">
                  <template v-if="currentOOSession.oo_link">
                    <v-dialog
                      content-class="transparent elevation-0"
                      v-model="ooSessionDialog"
                      persistent
                      :max-width="$vuetify.breakpoint.mobile ? '75vw' : '50vw'"
                    >
                      <template #activator="{ on }">
                        <v-btn
                          v-on="on"
                          color="primary"
                          class="black--text font-weight-bold"
                          >JOIN NOW</v-btn
                        >
                      </template>

                      <div
                        class="d-flex justify-end pa-4 black"
                        style="width: 100%"
                      >
                        <v-btn
                          icon
                          dark
                          color="pink"
                          @click="ooSessionDialog = false"
                        >
                          <v-icon> fas fa-times-circle </v-icon>
                        </v-btn>
                      </div>

                      <v-responsive :aspect-ratio="16 / 9">
                        <iframe
                          frameborder="0"
                          :src="currentOOSession.oo_link"
                          style="height: 100%; width: 100%"
                        ></iframe
                      ></v-responsive>
                    </v-dialog>
                  </template>
                </v-col>
              </v-col>
            </v-row>

            <v-row
              justify="start"
              align="start"
              class="mt-5 sessions-agenda-lobby-row"
            >
            </v-row>

            <v-row
              justify="start"
              align="start"
              class="mt-5 sessions-agenda-lobby-row"
            >
              <v-col cols="8" class="px-0">
                <div
                  class="pl-2 py-3 black text-left white--text"
                  style="font-size: 1.2rem"
                >
                  <h3 class="pa-4">SURVEY AND INTRODUCTION</h3>
                </div>
                <div class="py-6 d-flex white pl-2 pr-4">
                  <v-col cols="6">
                    <div class="d-flex white pl-2 pr-4">
                      <v-col cols="12" class="">
                        <v-col
                          cols="12"
                          class="agenda-container d-flex flex-column"
                        >
                          <v-col
                            cols="12"
                            class="ageda-container mb-4 d-flex justify-start"
                          >
                            <v-img
                              contain
                              max-width="225px"
                              src="https://s3.eu-west-1.amazonaws.com/vod.eventcdn.net/pandora/smc2021/workshop_QR_code.png"
                            />
                          </v-col>

                          <v-col
                            cols="12"
                            class="text-left d-flex justify-start"
                          >
                            <h2 class="session-title">
                              Complete session surveys by scanning this QR-code.
                            </h2>
                          </v-col>
                        </v-col>
                      </v-col>
                    </div>
                  </v-col>

                  <v-col cols="6" class="pl-8 pr-8">
                    <div class="d-flex white pl-2 pr-4">
                      <v-col cols="12" class="">
                        <v-col
                          cols="12"
                          class="agenda-container d-flex flex-column"
                        >
                          <v-col
                            cols="12"
                            class="ageda-container mb-4 d-flex justify-start"
                          >
                            <v-img
                              contain
                              max-width="225px"
                              src="https://s3.eu-west-1.amazonaws.com/vod.eventcdn.net/pandora/smc2021/Conference_Full_Survey_QR_Code.png"
                            />
                          </v-col>

                          <v-col
                            cols="12"
                            class="text-left d-flex justify-start"
                          >
                            <h2 class="session-title">
                              Please complete the Pandora Dares Store Manager Conference survey.
                            </h2>
                          </v-col>
                        </v-col>
                      </v-col>
                    </div>
                  </v-col>
                </div>
              </v-col>
            </v-row>

            <!-- Sessions in agenda section -->
            <v-row
              justify="center"
              align="center"
              class="mt-5 sessions-agenda-lobby-row"
            >
              <v-col cols="8" class="pb-0 px-0">
                <div
                  class="pl-2 py-3 black text-left white--text"
                  style="font-size: 1.2rem"
                >
                  <h3 class="pa-4">SESSIONS</h3>
                </div>
                <div class="py-6 d-flex white pl-2 pr-4">
                  <v-col cols="2" class="mt-4 mb-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      width="147"
                      height="173"
                      viewBox="0 0 147 173"
                      class="date-svg"
                    >
                      <defs>
                        <filter
                          id="a"
                          x="0"
                          y="1"
                          width="147"
                          height="172"
                          filterUnits="userSpaceOnUse"
                        >
                          <feOffset dy="2" input="SourceAlpha" />
                          <feGaussianBlur stdDeviation="2" result="b" />
                          <feFlood flood-opacity="0.102" />
                          <feComposite operator="in" in2="b" />
                          <feComposite in="SourceGraphic" />
                        </filter>
                      </defs>
                      <g transform="translate(6)">
                        <g class="g" transform="matrix(1, 0, 0, 1, -6, 0)">
                          <rect
                            class="a"
                            width="135"
                            height="160"
                            rx="10"
                            transform="translate(6 5)"
                          />
                        </g>
                        <path
                          class="b"
                          d="M10,0H125a10,10,0,0,1,10,10V53H0V10A10,10,0,0,1,10,0Z"
                          transform="translate(0 5)"
                        />
                        <circle
                          class="c"
                          cx="7"
                          cy="7"
                          r="7"
                          transform="translate(24 12)"
                        />
                        <circle
                          class="c"
                          cx="7"
                          cy="7"
                          r="7"
                          transform="translate(98 12)"
                        />
                        <rect
                          class="d"
                          width="10"
                          height="24"
                          rx="5"
                          transform="translate(26)"
                        />
                        <rect
                          class="d"
                          width="10"
                          height="24"
                          rx="5"
                          transform="translate(100)"
                        />
                        <text class="e" transform="translate(27 47)">
                          <tspan y="80">14</tspan>
                        </text>
                        <text class="f" transform="translate(68 47)">
                          <tspan style="text-anchor: middle" y="0">
                            TUESDAY
                          </tspan>
                        </text>
                        <text class="f" transform="translate(68 150)">
                          <tspan x="-14" y="0">SEP</tspan>
                        </text>
                      </g>
                    </svg>
                  </v-col>
                  <v-col cols="10" class="pl-8 pr-8">
                    <v-col
                      cols="12"
                      v-for="session in sessions.filter(
                        (session) => session.day === 1
                      )"
                      :key="session.id"
                      class="agenda-container mt-4 mb-4 d-flex"
                    >
                      <v-col cols="6" v-if="event.state === 'ONDEMAND'">
                        <v-lazy>
                          <v-responsive :aspect-ratio="16 / 9">
                            <div
                              style="
                                background-size: cover;
                                background-repeat: no-repeat;
                                height: 100%;
                                width: 100%;
                              "
                              :style="{
                                'background-image': `url(${session.details.thumb})`,
                              }"
                              class="d-flex align-center justify-center"
                            >
                              <v-btn
                                fab
                                color="primary"
                                class="rounded-circle"
                                :to="{
                                  name: 'SessionDetails',
                                  params: { id: session.id },
                                }"
                                ><v-icon> fas fa-play</v-icon></v-btn
                              >
                            </div>
                          </v-responsive>
                        </v-lazy>
                      </v-col>
                      <v-col
                        :cols="event.state === 'ONDEMAND' ? 6 : 12"
                        class="text-left"
                      >
                        <v-chip
                          label
                          color="background"
                          class="white--text mb-4"
                          v-if="event.state === 'ONDEMAND'"
                          >ON DEMAND</v-chip
                        >
                        <p class="session-date" v-else>
                          {{ session.details.time }}
                        </p>
                        <h2 class="session-title mb-4">{{ session.name }}</h2>
                        <div class="d-flex flex-wrap">
                          <p
                            class="session-name"
                            v-for="(speaker, idx) in session.speakers"
                            :key="idx"
                          >
                            <template v-if="idx !== 0">, </template>
                            {{ speaker.name }}
                          </p>
                        </div>

                        <div
                          class="
                            d-flex
                            align-center
                            justify-space-between
                            mt-12
                          "
                        >
                          <p
                            class="session-points mb-0"
                            v-if="event.state !== 'ONDEMAND'"
                          >
                            {{ session.details.points }} pts
                          </p>

                          <v-btn
                            v-if="session.more_details"
                            style="text-transform: unset"
                            class="
                              text-decoration-underline
                              session-points
                              px-0
                            "
                            text
                            :to="{
                              name: 'SessionDetails',
                              params: { id: session.id },
                            }"
                            >See more details</v-btn
                          >
                        </div>
                      </v-col>
                    </v-col>
                  </v-col>
                </div>
              </v-col>
              <v-col cols="4">
                <!-- If i remove this the layout fucks up -->
              </v-col>
            </v-row>

            <v-row
              justify="center"
              align="center"
              class="sessions-agenda-lobby-row mt-0"
            >
              <v-col cols="8" class="pt-0 px-0">
                <v-divider class="pink"></v-divider>

                <div class="py-6 d-flex white pl-2 pr-4">
                  <v-col cols="2" class="mt-4 mb-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      width="147"
                      height="173"
                      viewBox="0 0 147 173"
                      class="date-svg"
                    >
                      <defs>
                        <filter
                          id="a"
                          x="0"
                          y="1"
                          width="147"
                          height="172"
                          filterUnits="userSpaceOnUse"
                        >
                          <feOffset dy="2" input="SourceAlpha" />
                          <feGaussianBlur stdDeviation="2" result="b" />
                          <feFlood flood-opacity="0.102" />
                          <feComposite operator="in" in2="b" />
                          <feComposite in="SourceGraphic" />
                        </filter>
                      </defs>
                      <g transform="translate(6)">
                        <g class="g" transform="matrix(1, 0, 0, 1, -6, 0)">
                          <rect
                            class="a"
                            width="135"
                            height="160"
                            rx="10"
                            transform="translate(6 5)"
                          />
                        </g>
                        <path
                          class="b"
                          d="M10,0H125a10,10,0,0,1,10,10V53H0V10A10,10,0,0,1,10,0Z"
                          transform="translate(0 5)"
                        />
                        <circle
                          class="c"
                          cx="7"
                          cy="7"
                          r="7"
                          transform="translate(24 12)"
                        />
                        <circle
                          class="c"
                          cx="7"
                          cy="7"
                          r="7"
                          transform="translate(98 12)"
                        />
                        <rect
                          class="d"
                          width="10"
                          height="24"
                          rx="5"
                          transform="translate(26)"
                        />
                        <rect
                          class="d"
                          width="10"
                          height="24"
                          rx="5"
                          transform="translate(100)"
                        />
                        <text class="e" transform="translate(27 47)">
                          <tspan y="80">15</tspan>
                        </text>
                        <text class="f" transform="translate(68 47)">
                          <tspan style="text-anchor: middle" y="0">
                            WEDNESDAY
                          </tspan>
                        </text>
                        <text class="f" transform="translate(68 150)">
                          <tspan x="-14" y="0">SEP</tspan>
                        </text>
                      </g>
                    </svg>
                  </v-col>
                  <v-col cols="10" class="pl-8 pr-8">
                    <v-col
                      cols="12"
                      v-for="session in sessions.filter(
                        (session) => session.day === 2
                      )"
                      :key="session.id"
                      class="agenda-container mt-4 mb-4 d-flex"
                    >
                      <v-col cols="6" v-if="event.state === 'ONDEMAND'">
                        <v-lazy>
                          <v-responsive :aspect-ratio="16 / 9">
                            <div
                              style="
                                background-size: cover;
                                background-repeat: no-repeat;
                                height: 100%;
                                width: 100%;
                              "
                              :style="{
                                'background-image': `url(${session.details.thumb})`,
                              }"
                              class="d-flex align-center justify-center"
                            >
                              <v-btn
                                fab
                                color="primary"
                                class="rounded-circle"
                                :to="{
                                  name: 'SessionDetails',
                                  params: { id: session.id },
                                }"
                                ><v-icon> fas fa-play</v-icon></v-btn
                              >
                            </div>
                          </v-responsive>
                        </v-lazy>
                      </v-col>
                      <v-col
                        :cols="event.state === 'ONDEMAND' ? 6 : 12"
                        class="text-left"
                      >
                        <v-chip
                          label
                          color="background"
                          class="white--text mb-4"
                          v-if="event.state === 'ONDEMAND'"
                          >ON DEMAND</v-chip
                        >
                        <p class="session-date" v-else>
                          {{ session.details.time }}
                        </p>
                        <h2 class="session-title mb-4">{{ session.name }}</h2>
                        <div class="d-flex flex-wrap">
                          <p
                            class="session-name"
                            v-for="(speaker, idx) in session.speakers"
                            :key="idx"
                          >
                            <template v-if="idx !== 0">, </template>
                            {{ speaker.name }}
                          </p>
                        </div>

                        <div
                          class="
                            d-flex
                            align-center
                            justify-space-between
                            mt-12
                          "
                        >
                          <p
                            class="session-points mb-0"
                            v-if="event.state !== 'ONDEMAND'"
                          >
                            {{ session.details.points }} pts
                          </p>

                          <v-btn
                            v-if="session.more_details"
                            style="text-transform: unset"
                            class="
                              text-decoration-underline
                              session-points
                              px-0
                            "
                            text
                            :to="{
                              name: 'SessionDetails',
                              params: { id: session.id },
                            }"
                            >See more details</v-btn
                          >
                        </div>
                      </v-col>
                    </v-col>
                  </v-col>
                </div>
              </v-col>
              <v-col cols="4"> </v-col>
            </v-row>

            <v-row
              justify="center"
              align="center"
              class="sessions-agenda-lobby-row mt-0"
            >
              <v-col cols="8" class="pt-0 px-0">
                <v-divider class="pink"></v-divider>

                <div class="py-6 d-flex white pl-2 pr-4">
                  <v-col cols="2" class="mt-4 mb-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      width="147"
                      height="173"
                      viewBox="0 0 147 173"
                      class="date-svg"
                    >
                      <defs>
                        <filter
                          id="a"
                          x="0"
                          y="1"
                          width="147"
                          height="172"
                          filterUnits="userSpaceOnUse"
                        >
                          <feOffset dy="2" input="SourceAlpha" />
                          <feGaussianBlur stdDeviation="2" result="b" />
                          <feFlood flood-opacity="0.102" />
                          <feComposite operator="in" in2="b" />
                          <feComposite in="SourceGraphic" />
                        </filter>
                      </defs>
                      <g transform="translate(6)">
                        <g class="g" transform="matrix(1, 0, 0, 1, -6, 0)">
                          <rect
                            class="a"
                            width="135"
                            height="160"
                            rx="10"
                            transform="translate(6 5)"
                          />
                        </g>
                        <path
                          class="b"
                          d="M10,0H125a10,10,0,0,1,10,10V53H0V10A10,10,0,0,1,10,0Z"
                          transform="translate(0 5)"
                        />
                        <circle
                          class="c"
                          cx="7"
                          cy="7"
                          r="7"
                          transform="translate(24 12)"
                        />
                        <circle
                          class="c"
                          cx="7"
                          cy="7"
                          r="7"
                          transform="translate(98 12)"
                        />
                        <rect
                          class="d"
                          width="10"
                          height="24"
                          rx="5"
                          transform="translate(26)"
                        />
                        <rect
                          class="d"
                          width="10"
                          height="24"
                          rx="5"
                          transform="translate(100)"
                        />
                        <text class="e" transform="translate(27 47)">
                          <tspan y="80">16</tspan>
                        </text>
                        <text class="f" transform="translate(68 47)">
                          <tspan style="text-anchor: middle" y="0">
                            THURSDAY
                          </tspan>
                        </text>
                        <text class="f" transform="translate(68 150)">
                          <tspan x="-14" y="0">SEP</tspan>
                        </text>
                      </g>
                    </svg>
                  </v-col>
                  <v-col cols="10" class="pl-8 pr-8">
                    <v-col
                      cols="12"
                      v-for="session in sessions.filter(
                        (session) => session.day === 3
                      )"
                      :key="session.id"
                      class="agenda-container mt-4 mb-4 d-flex"
                    >
                      <v-col cols="6" v-if="event.state === 'ONDEMAND'">
                        <v-lazy>
                          <v-responsive :aspect-ratio="16 / 9">
                            <div
                              style="
                                background-size: cover;
                                background-repeat: no-repeat;
                                height: 100%;
                                width: 100%;
                              "
                              :style="{
                                'background-image': `url(${session.details.thumb})`,
                              }"
                              class="d-flex align-center justify-center"
                            >
                              <v-btn
                                fab
                                color="primary"
                                class="rounded-circle"
                                :to="{
                                  name: 'SessionDetails',
                                  params: { id: session.id },
                                }"
                                ><v-icon> fas fa-play</v-icon></v-btn
                              >
                            </div>
                          </v-responsive>
                        </v-lazy>
                      </v-col>
                      <v-col
                        :cols="event.state === 'ONDEMAND' ? 6 : 12"
                        class="text-left"
                      >
                        <v-chip
                          label
                          color="background"
                          class="white--text mb-4"
                          v-if="event.state === 'ONDEMAND'"
                          >ON DEMAND</v-chip
                        >
                        <p class="session-date" v-else>
                          {{ session.details.time }}
                        </p>
                        <h2 class="session-title mb-4">{{ session.name }}</h2>
                        <div class="d-flex flex-wrap">
                          <p
                            class="session-name"
                            v-for="(speaker, idx) in session.speakers"
                            :key="idx"
                          >
                            <template v-if="idx !== 0">, </template>
                            {{ speaker.name }}
                          </p>
                        </div>

                        <div
                          class="
                            d-flex
                            align-center
                            justify-space-between
                            mt-12
                          "
                        >
                          <p
                            class="session-points mb-0"
                            v-if="event.state !== 'ONDEMAND'"
                          >
                            {{ session.details.points }} pts
                          </p>

                          <v-btn
                            v-if="session.more_details"
                            style="text-transform: unset"
                            class="
                              text-decoration-underline
                              session-points
                              px-0
                            "
                            text
                            :to="{
                              name: 'SessionDetails',
                              params: { id: session.id },
                            }"
                            >See more details</v-btn
                          >
                        </div>
                      </v-col>
                    </v-col>
                  </v-col>
                </div>
              </v-col>
              <v-col cols="4"> </v-col>
            </v-row>

            <!-- Dare to explore section -->
            <v-row class="mt-5 sessions-agenda-lobby-row">
              <v-col cols="8" class="px-0">
                <div
                  class="pl-2 py-3 black text-left white--text"
                  style="font-size: 1.2rem"
                >
                  <h3 class="pa-4">EXTRA SESSIONS</h3>
                </div>
                <div class="pl-2 pr-4 py-6 d-flex white flex-row flex-wrap">
                  <v-col cols="12" class="d-flex flex-row flex-wrap pl-0">
                    <v-col
                      cols="4"
                      v-for="(session, idx) in extraSessions"
                      :key="idx"
                      class="dare-to-explore-container ma-4 d-flex"
                    >
                      <v-col cols="12">
                        <v-dialog
                          @click:outside="$refs['extraSessions'][idx].pause()"
                          @keydown="$refs['extraSessions'][idx].pause()"
                          :max-width="
                            $vuetify.breakpoint.mobile ? '75vw' : '50vw'
                          "
                        >
                          <template #activator="{ on }">
                            <v-responsive :aspect-ratio="16 / 9">
                              <div
                                style="
                                  height: 100%;
                                  width: 100%;
                                  background-size: cover;
                                  background-repeat: no-repeat;
                                "
                                class="d-flex align-center justify-center"
                                :style="{
                                  'background-image': `url(${session.thumb})`,
                                }"
                              >
                                <v-btn
                                  fab
                                  color="primary"
                                  class="rounded-circle"
                                  v-on="on"
                                  ><v-icon> fas fa-play</v-icon></v-btn
                                >
                              </div>
                            </v-responsive>
                          </template>

                          <v-responsive :aspect-ratio="16 / 9">
                            <video
                              ref="extraSessions"
                              autoplay
                              controls
                              preload="metadata"
                              style="height: 100%; width: 100%"
                              :poster="session.thumb"
                              @ended="onExtraSessionEnd(session)"
                            >
                              <source :src="session.src" /></video
                          ></v-responsive>

                          <v-card>
                            <v-card-title>About</v-card-title>
                            <v-card-text
                              class="text-left"
                              style="max-width: 75%"
                            >
                              {{ session.details.about }}
                            </v-card-text>
                          </v-card>
                        </v-dialog>
                      </v-col>
                      <v-col cols="12" class="text-left">
                        <p class="session-date">{{ session.details.time }}</p>
                        <h2 class="session-title mb-4">{{ session.name }}</h2>
                        <div class="d-flex flex-wrap">
                          <p
                            class="session-name"
                            v-for="(speaker, speakerIdx) in session.speakers"
                            :key="speakerIdx"
                          >
                            <template v-if="idx !== 0">, </template>
                            {{ speaker.name }}
                          </p>
                        </div>

                        <p
                          class="session-points mt-12"
                          v-if="event.state !== 'ONDEMAND'"
                        >
                          {{ session.details.points }} pts
                        </p>
                      </v-col>
                    </v-col>
                  </v-col>
                </div>
              </v-col>
              <v-col cols="4"> </v-col>
            </v-row>

            <!-- Hidden questions -->
            <AppHiddenQuestion
              top="41.75%"
              right="5%"
              question="How many global influencers will be involved in the reset of Pandora ME?"
              :options="['Two', 'Five', 'Six']"
              answer="Five"
              :tracking-id="27"
            ></AppHiddenQuestion>

            <AppHiddenQuestion
              top="10%"
              right="20%"
              :tracking-id="37"
            ></AppHiddenQuestion>

            <AppHiddenQuestion
              top="35%"
              left="0.5%"
              :tracking-id="33"
            ></AppHiddenQuestion>
          </v-container>
        </div>
      </v-sheet>
    </aspect-ratio-layout>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full.esm";
import ResponsiveLayout from "../layouts/ResponsiveLayout.vue";
import AspectRatioLayout from "../layouts/16by9Layout.vue";
import theatreMockData from "../assets/data/theatre-mock-data.json";

import VideoPlayerManager from "@/services/VideoPlayerManager";
import axios from "axios";

import EXTRA_SESSIONS from "@/assets/data/extra_sessions.data.js";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    ResponsiveLayout,
    AspectRatioLayout,
  },

  data() {
    return {
      credentials: {
        username: null,
        password: null,
      },
      error: false,
      time: "2021-09-14T15:30:00",
      testTime: "2020-09-14T15:30:00",
      form: {
        name: null,
        email: null,
        store_name: null,
        territory_manager: null,
        registration_type: null,
        livehouse_terms: null,
        //pandora_terms: null,
      },
      isPlaying: false,
      hideCountDown: false,
      dialog: false,
      dialogFirst: false,
      dialogSecretSecond: false,
      dialogSecretThird: false,
      column: null,
      row: null,
      overlay: false,
      introductionVideo: {
        time: "",
        src: "https://vod.eventcdn.net/pandora/smc2021/introduction/Introduction_video_with_music_final.mp4",
        title: "Introduction Video",
        speakers: [
          {
            name: "",
          },
        ],
        splash:
          "https://vod.eventcdn.net/pandora/smc2021/introduction/Introduction_thumb.png",
        points: 25,
      },

      // Extra sessions - follows same structure as sessions from API (id not required)
      extraSessions: EXTRA_SESSIONS,

      // Dialogs
      ooSessionDialog: false,
      secondarySessionDialog: false,
    };
  },

  async mounted() {
    await this.$store.dispatch("updateSessions");
  },

  computed: {
    ...mapState(["event", "ooEvent", "sessions"]),
    ...mapGetters([
      "me",
      "currentSession",
      "currentOOSession",
      "countdownTime",
      "countdownOOTime",
    ]),
  },

  methods: {
    navigate() {
      const next = this.$route.query?.next;
      if (next) {
        this.$router.push(next);
      } else if (this.$store.getters.isAdmin) {
        this.$router.push({ name: "AdminHome" });
      } else {
        this.$router.push({ name: "Home" });
      }
    },
    async login() {
      this.error = false;
      try {
        await this.$store.dispatch("login", this.credentials);
      } catch (e) {
        this.error = true;
        console.debug("Login error:", e);
      }
    },
    async onLogin() {
      // Navigate
      this.navigate();
    },

    playWelcomeVideo() {
      const playArrow = document.getElementsByClassName(
        "play-btn-arrow-introduction"
      )[0];

      /*const hasPlayed = VideoPlayerManager.shouldPlay(
        this.welcomeVideoSrc
      );*/

      if (this.isPlaying == false) {
        playArrow.classList.remove("fa-play");
        playArrow.classList.add("fa-pause");

        this.$refs.introductionVideo.play();
        this.isPlaying = true;
      } else {
        this.$refs.introductionVideo.pause();
        this.isPlaying = false;

        playArrow.classList.remove("fa-pause");

        playArrow.classList.add("fa-play");
      }
    },

    /*playWelcomeVideo() {
      const playArrow = document.getElementsByClassName("play-btn-arrow")[0];

      if (this.isPlaying == false) {
        playArrow.classList.remove("fa-play");
        playArrow.classList.add("fa-pause");

        this.$refs.welcomeVideo.play();
        this.isPlaying = true;
      } else {
        this.$refs.welcomeVideo.pause();
        this.isPlaying = false;

        playArrow.classList.remove("fa-pause");

        playArrow.classList.add("fa-play");
      }
    },*/

    toggleVideoOrCountDown() {
      var currentDate = new Date();
      var currentDateAsIso = currentDate.toISOString();

      if (this.testTime <= currentDateAsIso) {
        this.hideCountDown = true;
      }
    },

    async onIntroductionVideoEnd() {
      const id = 20; // Introduction Video Day 1
      if (this.event.state !== "ONDEMAND") {
        await this.$tracking.trackPoint(id);
      }
    },

    async onExtraSessionEnd(extraSession) {
      // For url
      if (this.event.state !== "ONDEMAND") {
        await axios.get(extraSession.tracking_url);
      }
    },
  },

  watch: {
    async ooSessionDialog(value) {
      if (value) {
        await this.onExtraSessionEnd(this.currentOOSession);
      }
    },
    async secondarySessionDialog(value) {
      if (value) {
        const session = await this.$store.dispatch("getSession", 16);

        await this.onExtraSessionEnd(session);
      }
    },
  },
};
</script>

<style scoped>
/*.main {
  background-image: url("https://vod.eventcdn.net/pandora/smc2021/Theatre_Bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

@media screen and (min-width: 1020px) {
  .main {
    background-image: url("https://vod.eventcdn.net/pandora/smc2021/Theatre_Bg.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    backface-visibility: hidden;
  }
}*/

.v-application.livehouse-app .countdown-value {
  font-size: 3rem !important;
  color: var(--v-primary-base) !important;
  line-height: 5rem !important;
  font-weight: 700 !important;
}

@media screen and (max-width: 992px) {
  .v-application.livehouse-app .countdown-value {
    font-size: 3rem !important;
    line-height: 3.5rem !important;
  }
}

.countdown-with-pink-text {
  color: var(--pink) !important;
  font: var(--unnamed-font-style-normal) normal bold 80px/60px
    var(--unnamed-font-family-pan-text);
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: center;
  font: normal normal bold 80px/60px Pan Text;
}

.countdown-headline {
  font: var(--unnamed-font-style-normal) normal
    var(--unnamed-font-weight-normal) 22px/32px
    var(--unnamed-font-family-pan-text);
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: center;
  font: normal normal normal 22px/32px Pan Text;
}

.unit {
  font: var(--unnamed-font-style-normal) normal
    var(--unnamed-font-weight-normal) var(--unnamed-font-size-18) /
    var(--unnamed-line-spacing-22) var(--unnamed-font-family-pan-text);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--darker-grey);
  text-align: center;
  font: normal normal normal 18px/22px Pan Text;
}

.countdown-container {
  margin-top: 10.5rem;
}
</style>
