const BASE_URL = "https://pandora-smc2021.portal.eventcdn.net/tracking/";

export default [
  {
    name: "Holiday Gift Sets",
    speakers: [],
    details: {
      time: "12:46",
      points: "25",
      about:
        "Holiday Gift Sets In this session we will not only review our Holiday Gift Set line up, but also share what you can expect this Holiday Season to be like from the consumer’s perspective in order to maximize sales and drive repeat business, review LUX Selling and how to link gift sets as part of the techniques using examples and provide you with several helpful tools that can be downloaded and used to train or review with your store teams.",
    },
    thumb:
      "https://vod.eventcdn.net/pandora/smc2021/extraSessions/holiday_gift_sets_2021_final_thumb.png",
    src:
      "https://vod.eventcdn.net/pandora/smc2021/extraSessions/holiday_gift_sets_2021_final.mp4",
    tracking_url: `${BASE_URL}61/`,
  },

  {
    name: "Engaging the Shopper in Line",
    speakers: [],
    details: {
      time: "15:40",
      points: "25",
      about:
        "In this short session, we will go through how we care to empower and support our line leads in being able to drive both sales and a unique Pandora shopping experience. We will share best practices to help you and your teams achieve the best outcome of our shoppers that are waiting in line this holiday season.",
    },
    thumb:
      "https://vod.eventcdn.net/pandora/smc2021/extraSessions/Engaging_the_Shopper_in_Line_thumb.png",
    src:
      "https://vod.eventcdn.net/pandora/smc2021/extraSessions/Engaging+the+Shopper+in+Line.mp4",
    tracking_url: `${BASE_URL}62/`,
  },

  {
    name: "Creating a Shopper for Life Through Omnichannel",
    speakers: [],
    details: {
      time: "25:41",
      points: "25",
      about:
        "In this short session, we will be going through all things omnichannel. We will discuss how we can maximize each omnichannel experience for our shoppers even in the busiest times of the year and understand what it means to become a key leading omnichannel retailer!",
    },
    thumb:
      "https://vod.eventcdn.net/pandora/smc2021/extraSessions/Maximizing_the_Omni_Experience_thumb.png",
    src:
      "https://vod.eventcdn.net/pandora/smc2021/extraSessions/Maximizing+the+Omni+Experience.mp4",
    tracking_url: `${BASE_URL}63/`,
  },

  {
    name: "Using Fashion Trends to Connect with Shoppers",
    speakers: [],
    details: {
      time: "15:40",
      points: "25",
      about:
        "This engaging session will dive into how fashion trends can influence shopper’s habits and behaviors. We will review how Pandora stays relevant by connecting with our shopper and share some best practices on jewelry and styling trends that will help to drive your sales results.",
    },
    thumb:
      "https://vod.eventcdn.net/pandora/smc2021/extraSessions/Using_Fashion_Trends_to_Connect_with_Shoppers_thumb.png",
    src:
      "https://vod.eventcdn.net/pandora/smc2021/extraSessions/Using+Fashion+Trends+to+Connect+with+Shoppers.mp4",
    tracking_url: `${BASE_URL}64/`,
  },
  {
    name: "Introduction Video",
    speakers: [],
    details: {
      time: "1:56",
      points: "25",
      about:
        "Introduction Video",
    },
    thumb:
      "https://vod.eventcdn.net/pandora/smc2021/introduction/Introduction_thumb.png",
    src:
      "https://vod.eventcdn.net/pandora/smc2021/introduction/Introduction_video_with_music_final.mp4",
    tracking_url: `${BASE_URL}64/`,
  },
];
