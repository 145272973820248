<template>
  <div v-if="session">
    <!-- Mobile layout -->
    <responsive-layout
      v-if="$vuetify.breakpoint.mobile"
      poster="https://vod.eventcdn.net/pandora/smc2021/Theatre_Bg.png"
    >
      <v-sheet
        style="min-height: 100vh"
        class="d-flex flex-column justify-center transparent"
        light
      >
        <div class="main py-8 flex-fill">
          <v-container fluid>
            <!-- 'Breadcrumb' navigation at the top -->
            <v-row justify="start" align="start" class="mt-5 lobby-router-row">
              <v-col
                cols="12"
                class="pl-0 d-flex align-start justify-start flex-column ml-1"
              >
                <!--<v-btn
                  class="ma-2 white--text lobby-back-button rounded-0 mr-0 mb-0"
                  color="black"
                >
                  <router-link class="white--text" to="/lobby">
                    <v-icon left dark color="pink" class="back-arrow-theatre">
                      fas fa-chevron-left
                    </v-icon>
                    <span class="back-arrow-lobby-link">To theatre</span>
                  </router-link>
                </v-btn>-->
                <AppBackButton :to="{ name: 'Theatre' }" class="ml-2"
                  >TO THEATRE</AppBackButton
                >
                <div class="route-name-background-container-information ml-2">
                  <span class="theatre-route-name">{{ session.name }}</span>
                </div>
              </v-col>
            </v-row>

            <!-- Downloads section -->
            <v-row
              justify="start"
              align="start"
              class="sessions-agenda-lobby-row"
            >
              <v-col cols="12" v-if="event.state === 'ONDEMAND'">
                <!-- Video -->
                <lh-video
                  v-if="session.details.src"
                  :src="session.details.src"
                  :splash="session.details.thumb"
                ></lh-video>

                <v-responsive :aspect-ratio="16 / 9" v-else>
                  <div
                    style="
                      height: 100%;
                      width: 100%;
                      background: rgba(41, 37, 44, 0.9);
                    "
                    class="d-flex align-center justify-center"
                  >
                    <h2 class="white--text">
                      On Demand video is not ready yet.
                      <br />
                      Check again later.
                    </h2>
                  </div>
                </v-responsive>
              </v-col>

              <v-col>
                <!-- Sessions in agenda section -->

                <v-col cols="12" class="pb-0 pr-0 pl-0">
                  <div
                    class="pl-6 py-3 black text-left white--text"
                    style="font-size: 1.2rem"
                  >
                    <h3 class="pa-4">ABOUT THIS SESSION</h3>
                  </div>
                  <div class="py-6 d-flex white pl-1 pr-4">
                    <v-col cols="12">
                      <v-col
                        cols="12"
                        class="
                          agenda-container
                          d-flex
                          flex-row flex-wrap
                          white
                          pt-0
                          pb-0
                        "
                      >
                        {{ session.details.about }}
                      </v-col>
                    </v-col>
                  </div>
                </v-col>
              </v-col>

              <!-- Speaker bios section -->

              <v-col cols="12">
                <div
                  class="pl-1 py-3 black text-left white--text"
                  style="font-size: 1.2rem"
                >
                  <h3 class="pa-4">SPEAKER BIOS</h3>
                </div>
                <div class="py-6 d-flex white pr-4">
                  <v-col cols="12" class="pl-3 pr-3">
                    <v-col
                      cols="12"
                      v-for="(speaker, idx) in session.speakers"
                      :key="idx"
                      class="agenda-container d-flex white pl-2 pt-1"
                    >
                      <v-col cols="12" sm="6" class="pl-0">
                        <v-img
                          :src="speaker.image"
                          style="max-width: 100%"
                        ></v-img>
                      </v-col>
                      <v-col cols="12" sm="6" class="text-left">
                        <p class="mt-2 information-speaker-name">
                          {{ speaker.name }}
                        </p>
                        <p class="mt-2 information-speaker-description">
                          {{ speaker.description }}
                        </p>
                        <v-divider class="lighter-grey-base"></v-divider>
                      </v-col>
                    </v-col>
                  </v-col>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-sheet>
    </responsive-layout>

    <!-- 16:9 layout for desktop devices -->
    <aspect-ratio-layout
      v-else
      poster="https://vod.eventcdn.net/pandora/smc2021/Theatre_Bg.png"
    >
      <v-sheet class="d-flex flex-column transparent" light>
        <div class="main">
          <v-container fluid style="margin-bottom: 150px">
            <!-- 'Breadcrumb' navigation at the top -->
            <v-row justify="start" align="start" class="mt-5 lobby-router-row">
              <v-col
                cols="8"
                class="pl-0 d-flex align-start justify-start ml-10"
              >
                <!--<v-btn
                  class="ma-2 white--text lobby-back-button rounded-0 mr-0"
                  color="black"
                >
                  <router-link class="white--text" to="/lobby">
                    <v-icon left dark color="pink" class="back-arrow-theatre">
                      fas fa-chevron-left
                    </v-icon>
                    <span class="back-arrow-lobby-link">To theatre</span>
                  </router-link>
                </v-btn>-->
                <AppBackButton :to="{ name: 'Theatre' }" class="mt-2 ml-4"
                  >TO THEATRE</AppBackButton
                >
                <div
                  class="
                    route-name-background-container-meet-your-colleagues
                    mt-2
                  "
                >
                  <span class="theatre-route-name">{{ session.name }}</span>
                </div>
              </v-col>
            </v-row>

            <!-- Downloads section -->
            <v-row
              justify="start"
              align="start"
              class="sessions-agenda-lobby-row"
              style="max-height: 700px"
            >
              <v-col cols="8">
                <div v-if="event.state === 'ONDEMAND'" class="mb-8">
                  <!-- Video -->
                  <lh-video
                    v-if="session.details.src"
                    :src="session.details.src"
                    :splash="session.details.thumb"
                  ></lh-video>

                  <v-responsive :aspect-ratio="16 / 9" v-else>
                    <div
                      style="
                        height: 100%;
                        width: 100%;
                        background: rgba(41, 37, 44, 0.9);
                      "
                      class="d-flex align-center justify-center"
                    >
                      <h2 class="white--text">
                        On Demand video is not ready yet.
                        <br />
                        Check again later.
                      </h2>
                    </div>
                  </v-responsive>
                </div>

                <div
                  class="pl-6 py-3 black text-left white--text"
                  style="font-size: 1.2rem"
                >
                  <h3 class="pa-4">SPEAKER BIOS</h3>
                </div>
                <div class="py-6 d-flex white pr-4">
                  <v-col cols="12" class="pl-8 pr-8 d-flex flex-row flex-wrap">
                    <v-col
                      cols="6"
                      v-for="(speaker, idx) in session.speakers"
                      :key="idx"
                      class="
                        agenda-container
                        d-flex
                        white
                        pl-2
                        pt-1
                        flex-row flex-nowrap
                      "
                    >
                      <v-col cols="4" class="pl-0">
                        <v-img
                          :src="speaker.image"
                          style="max-width: 100%"
                        ></v-img>
                      </v-col>
                      <v-col cols="8" class="text-left">
                        <p class="mt-2 information-speaker-name">
                          {{ speaker.name }}
                        </p>
                        <p class="mt-2 information-speaker-description">
                          {{ speaker.description }}
                        </p>
                        <v-divider class="lighter-grey-base"></v-divider>
                      </v-col>
                    </v-col>
                  </v-col>
                </div>
              </v-col>

              <v-col cols="4" class="d-flex flex-column">
                <v-col cols="12" class="pb-0 pr-0 pl-0 pt-0">
                  <div
                    class="pl-6 py-3 black text-left white--text"
                    style="font-size: 1.2rem"
                  >
                    <h3 class="pa-4">ABOUT THIS SESSION</h3>
                  </div>
                  <div class="py-6 d-flex white pl-1 pr-4">
                    <v-col cols="12">
                      <v-col
                        cols="12"
                        class="
                          agenda-container
                          d-flex
                          flex-row flex-wrap
                          white
                          pt-0
                          pb-0
                        "
                      >
                        {{ session.details.about }}
                      </v-col>
                    </v-col>
                  </div>
                </v-col>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-sheet>
    </aspect-ratio-layout>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full.esm";
import ResponsiveLayout from "../layouts/ResponsiveLayout.vue";
import AspectRatioLayout from "../layouts/16by9Layout.vue";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    ResponsiveLayout,
    AspectRatioLayout,
  },

  data() {
    return {
      session: null,
    };
  },

  async mounted() {
    await this.$store.dispatch("updateSessions");

    this.session = await this.$store.dispatch(
      "getSession",
      +this.$route.params.id
    );
  },

  computed: {
    ...mapState(["event"]),
  },
};
</script>

<style scoped>
/*
.main {
  background-image: url("https://vod.eventcdn.net/pandora/smc2021/Theatre_Bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

@media screen and (min-width: 1020px) {
  .main {
    background-image: url("https://vod.eventcdn.net/pandora/smc2021/Theatre_Bg.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    backface-visibility: hidden;
  }
}*/
</style>
